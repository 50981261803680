const serviceUrlPro = 'https://darwin.xiaowuxiongdi.com/api'

// const serviceUrlDev = 'http://172.16.25.101:8888' // DD
const serviceUrlDev = 'http://172.16.25.39:5567' // qm
// const serviceUrlDev = 'http://172.16.24.200:5566' // 测试
const serviceUrlTest = 'http://172.16.24.200:5566' // 测试

const envServiceUrlMap = {
  development: serviceUrlDev,
  production: serviceUrlPro,
  buildTest: serviceUrlTest,
}
const serviceUrl = envServiceUrlMap[process.env.NODE_ENV]

const corpId = '?corpId=ding90ca1c32ca4e1c31acaaa37764f94726'
const version = '5'

const defaultBucket = 'silverdawn-hz'

export default {
  serviceUrl,

  // Endpoints
  loginEndpoint: `${serviceUrl}/sso/doLoginByTicket`,
  userInfoEndpoint: `${serviceUrl}/sso/userInfo`,
  isLoginEndpoint: `${serviceUrl}/sso/isLogin`,
  ssoAuthUrlEndpoint: `${serviceUrl}/sso/getSsoAuthUrl`,
  logoutEndpoint: `${serviceUrl}/sso/logout`,

  routeEndpoint: `${serviceUrl}/base/route`,
  menuEndpoint: `${serviceUrl}/base/menu`,

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
  storageVersionKeyName: 'version',
  defaultBucket,
  version,
  corpId,
}
